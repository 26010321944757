export const loadVueComponent = async (
    selector,
    chunk,
    el,
    componentName,
    hasTemplate
) => {
    // be safe and surround everything in try-catch to not affect other components
    try {
        const domEntryPoints = document.querySelectorAll(selector)

        // do nothing if we don't need to load anything
        if (!domEntryPoints.length) {
            return
        }

        // load everything we need simultaneously
        const [
            Vue,
            loadedComponent,
        ] = await Promise.all([
            import(/* webpackChunkName: 'vue' */ 'vue'),
            chunk(),
        ])

        // loop over entryPoints and create actual vue components
        for (const entryPoint of domEntryPoints) {
            if (!entryPoint) {
                continue
            }

            if (!hasTemplate) {
                entryPoint.setAttribute('inline-template', '')
            }

            new Vue.default({
                el,
                delimiters: ['[[', ']]'],
                components: {
                    [componentName]: loadedComponent.default,
                },
            })
        }
    }
    catch (error) {
        console.error(error)
    }
}
